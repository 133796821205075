import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Stack,
} from '@chakra-ui/react';
import * as cosmicAPI from 'api/cosmic';
import { LoadingIndicator } from 'common';
import { objectSlug } from 'constants/cosmic';
import { IFAQ } from 'interfaces/cosmic';
import Footer from 'layouts/Footer';
import Header from 'layouts/Header';
import { ICosmicContent } from 'pages/home';
import { buildObjectSlug, getCosmicEnvFromEnv } from 'utils/cosmic';
import { getClient, getSubDomain } from 'utils/misc';
import { parseHtml } from 'utils/parseHtml';

const FAQ = () => {
  const [cosmicContent, setCosmicContent] = useState<ICosmicContent | null>(
    null
  );

  useEffect(() => {
    const getContent = async () => {
      const subdomain = getSubDomain();
      const { client, cosmicObjectTypeSlug } = getClient(subdomain);
      const clientLowerCased = client.toLowerCase();
      const cosmicEnv = getCosmicEnvFromEnv(
        process.env.REACT_APP_ENVIRONMENT || 'DEV'
      );

      const data = await cosmicAPI.getContent({ type: cosmicObjectTypeSlug });

      if (!data?.objects?.length) {
        return;
      }

      const content: any = {};

      data?.objects?.forEach((item: any) => {
        switch (item.slug) {
          case buildObjectSlug(objectSlug.HOME_PAGE, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.homepage = item;
            break;

          case buildObjectSlug(objectSlug.HEADER, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.header = item;
            break;

          case buildObjectSlug(objectSlug.FOOTER, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.footer = item;
            break;

          case buildObjectSlug(objectSlug.SEO_INFO, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.pageSEO = item;
            break;

          case buildObjectSlug(objectSlug.THEME_COLOR, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.themeColor = item;
            break;

          case buildObjectSlug(objectSlug.FAQ, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.faq = item;
            break;

          default:
            break;
        }
      });

      setCosmicContent(content);
    };

    getContent();
  }, []);

  if (!cosmicContent) {
    return <LoadingIndicator containerHeight="100vh" />;
  }
  return (
    <>
      <Header data={cosmicContent.header} />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginTop={{ base: '140px', lg: '80px' }}
        minHeight={{ base: '60vh', lg: '80vh' }}
        w="full"
      >
        <Box
          alignItems="center"
          backgroundColor="#01183A"
          display="flex"
          height={{ base: '100px', md: '140px', lg: '150px' }}
          justifyContent="center"
          w="100%"
        >
          <Heading
            color="white"
            fontSize={{ base: '24px', md: '36px', lg: '42px' }}
          >
            Frequently Asked Questions
          </Heading>
        </Box>

        <Box mt={{ base: '22px', md: '28px', lg: '32px' }} w={{ base: '90%' }}>
          <Accordion allowMultiple color="#01183A" defaultIndex={[0]}>
            <Stack spacing={6}>
              {cosmicContent?.faq?.metadata?.faq.map((item: IFAQ) => (
                <AccordionItem
                  border="none"
                  key={item.question}
                  style={{
                    backgroundColor: '#F4F9FF',
                    borderRadius: '8px',
                  }}
                >
                  <h2>
                    <AccordionButton p="16px">
                      <Box flex="1" textAlign="left">
                        <Heading color="#333" fontSize="16px" fontWeight="bold">
                          <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: parseHtml(item.question),
                            }}
                          />
                        </Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Heading
                      color="#333"
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="1.75"
                    >
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: parseHtml(item.answer),
                        }}
                      />
                    </Heading>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Stack>
          </Accordion>
        </Box>
      </Box>
      <Footer data={cosmicContent.footer} />
    </>
  );
};

export default FAQ;
