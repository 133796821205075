import { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import * as cosmicAPI from 'api/cosmic';
import { LoadingIndicator } from 'common';
import { objectSlug } from 'constants/cosmic';
import { dividerStyles } from 'constants/styles';
import {
  IFAQCollection,
  IFooter,
  IHeader,
  IHomepage,
  IPageSEO,
  IThemeColor,
} from 'interfaces/cosmic';
import Footer from 'layouts/Footer';
import Header from 'layouts/Header';
import { buildObjectSlug, getCosmicEnvFromEnv } from 'utils/cosmic';
import { getClient, getSubDomain } from 'utils/misc';

import AppsListPage from './AppsListPage';
import DrugInformationPage from './DrugInformationPage';
import HeroBanner from './HeroBanner';
import HowItWorks from './HowItWorks';
import MedicalAssistedTreatment from './MedicalAssistedTreatment';
import SupervisorInfo from './SupervisorInfo';

export interface ICosmicContent {
  faq: IFAQCollection;
  homepage: IHomepage;
  header: IHeader;
  footer: IFooter;
  pageSEO: IPageSEO;
  themeColor: IThemeColor;
}

const Home = () => {
  const [cosmicContent, setCosmicContent] = useState<ICosmicContent | null>(
    null
  );

  useEffect(() => {
    const getContent = async () => {
      const subdomain = getSubDomain();
      const { client, cosmicObjectTypeSlug } = getClient(subdomain);
      const clientLowerCased = client.toLowerCase();
      const cosmicEnv = getCosmicEnvFromEnv(
        process.env.REACT_APP_ENVIRONMENT || 'DEV'
      );

      const data = await cosmicAPI.getContent({ type: cosmicObjectTypeSlug });

      if (!data?.objects?.length) {
        return;
      }

      const content: any = {};

      data?.objects?.forEach((item: any) => {
        switch (item.slug) {
          case buildObjectSlug(objectSlug.HOME_PAGE, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.homepage = item;
            break;

          case buildObjectSlug(objectSlug.HEADER, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.header = item;
            break;

          case buildObjectSlug(objectSlug.FOOTER, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.footer = item;
            break;

          case buildObjectSlug(objectSlug.SEO_INFO, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.pageSEO = item;
            break;

          case buildObjectSlug(objectSlug.THEME_COLOR, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.themeColor = item;
            break;

          default:
            break;
        }
      });

      setCosmicContent(content);
    };

    getContent();
  }, []);

  if (!cosmicContent) {
    return <LoadingIndicator containerHeight="100vh" />;
  }

  return (
    <div>
      <Header data={cosmicContent.header} />

      <HeroBanner data={cosmicContent.homepage.metadata.hero_banner} />

      <Box sx={dividerStyles} />

      <MedicalAssistedTreatment
        data={cosmicContent.homepage.metadata.medical_assisted_treatment}
      />
      <HowItWorks data={cosmicContent.homepage.metadata.how_it_works} />

      <DrugInformationPage
        data={cosmicContent.homepage.metadata.drug_information}
      />

      <Box sx={dividerStyles} />
      <SupervisorInfo
        data={cosmicContent.homepage.metadata.supervisor_information}
      />
      <Box sx={dividerStyles} />
      <AppsListPage data={cosmicContent.homepage.metadata.app_listing} />
      <Box
        sx={{
          ...dividerStyles,
          height: { base: '25px', md: '100px', lg: '140px' },
        }}
      />
      <Footer data={cosmicContent.footer} />
    </div>
  );
};

export default Home;
