import { Link as RouterLink } from 'react-router-dom';

import { Box, Image, Text } from '@chakra-ui/react';
import MATRxLogo from 'assets/images/png/matrx-logo.png';
import uiRoutes from 'constants/uiRoutes';

const LogoWithTagline = () => (
  <Box>
    <RouterLink to={uiRoutes.patientDashboard.profile}>
      <Image alt="logo" maxHeight="90%" maxWidth="160px" src={MATRxLogo} />
    </RouterLink>
    <Box display="flex">
      <Text
        color="primary.default"
        fontSize={{ base: '12px', sm: '12px' }}
        size="bodyTextSmall"
      >
        Your trusted source for <strong>M</strong>edication <strong>A</strong>
        ssisted <strong>T</strong>reatment
      </Text>
    </Box>
  </Box>
);

export default LogoWithTagline;
